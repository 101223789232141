<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card>
        <v-card-title>{{$t('dialogmultidelete.header')}}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">delete</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <!-- <v-div class="pl-10 font-weight-bold">{{$t('dialogmultidelete.total')}}&nbsp;{{ count_item }}&nbsp;{{$t('dialogmultidelete.title2')}}</v-div>
          <v-div class="pl-10 font-weight-bold">{{$t('dialogmultidelete.can_delete')}}&nbsp;{{ countshowdelete }}&nbsp;{{$t('dialogmultidelete.title2')}}</v-div>
          <v-div class="pl-10 font-weight-bold">{{$t('dialogmultidelete.cannot_delete')}}&nbsp;{{ countshowcannotdelete }}&nbsp;{{$t('dialogmultidelete.title2')}}</v-div>        -->
          <div class="text-center"><v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> folder </v-icon><b> {{ count_folder }}  {{$t('dialogmultidelete.title1')}}</b></p></v-chip>
          <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> mdi-file</v-icon><b> {{ count_file }} {{$t('dialogmultidelete.title2')}}</b></p></v-chip>
          <!-- <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon color="red"> mdi-delete-off </v-icon><b> {{ countshowcannotdelete }} {{$t('dialogmultidelete.title2')}}</b></p></v-chip> -->
          </div> 
          <v-list class="v-list-item-filefolder">
            <!--โชว์ไฟล์สามารถลบได้ -->
            <v-list-item v-for="item in showdelete" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
              </v-list-item-action>
              <v-badge
                :value="item.show_name"
                color="grey"
                :content="item.file_name"
                
                transition="slide-y-transition"
              ></v-badge>
              <v-list-item-content>
                 
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์ว่ามีสิทธิ์ลบหรือไม่-->
              <v-list-item-action v-if="check_show_progress === false">
                <v-chip
                  class="ma-2 font-weight-bold"
                  :color="item.permission_delete === true ? 'light-blue' : 'red'"
                  label
                  outlined
                >
                  {{ item.permission_delete === true ? $t('dialogmultidelete.canbedelete') : $t('dialogmultidelete.cannotdelete') }}
                </v-chip>
              </v-list-item-action>
              <!-- โชว์สถานะการลบว่าลบสำเร็จหรือไม่ -->
              <v-list-item-action v-else>
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" :color="color.theme" label outlined>
                  {{ item.file_status }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          <!-- โชว์ไฟลที่มีการล้อก -->
            <v-list-item v-for="item in showfilelock" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large :color="item.file_icon[1]">
                  {{ item.file_icon[0] }}
                </v-icon>
              </v-list-item-action>
              <v-badge
                :value="item.show_name"
                color="grey"
                :content="item.file_name"
                
                transition="slide-y-transition"
              ></v-badge>
              <v-list-item-content>
                 
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์ว่ามีสิทธิ์ลบหรือไม่-->
              <v-list-item-action >
                <v-chip
                  class="ma-2 font-weight-bold"
                  :color="item.permission_delete === true ? 'light-blue' : 'red'"
                  label
                  outlined
                >
                  {{ item.permission_delete === true ? $t('dialogmultidelete.canbedelete') : $t('dialogmultidelete.filelock')}}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <!--โชว์ไฟล์ไม่สามารถลบได้ -->
            <v-list-item v-for="item in showcannotdelete" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large :color="item.file_icon[1]">
                  {{ item.file_icon[0] }}
                </v-icon>
              </v-list-item-action>
              <v-badge
                :value="item.show_name"
                color="grey"
                :content="item.file_name"
                
                transition="slide-y-transition"
              ></v-badge>
              <v-list-item-content>
                 
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <!-- <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear> -->
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์ว่ามีสิทธิ์ลบหรือไม่-->
              <v-list-item-action >
                <v-chip
                  class="ma-2 font-weight-bold"
                  :color="item.permission_delete === true ? 'light-blue' : 'red'"
                  label
                  outlined
                >
                  {{ item.permission_delete === true ? $t('dialogmultidelete.canbedelete') : $t('dialogmultidelete.cannotdelete') }}
                </v-chip>
              </v-list-item-action>
              <!-- โชว์สถานะการลบว่าลบสำเร็จหรือไม่ -->
              <!-- <v-list-item-action v-else>
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" :color="color.theme" label outlined>
                  {{ item.file_status }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action> -->
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะลบหรือกดปิดdialog -->
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red"
            outlined
            @click="$emit('closedialog'),$emit('closebuttonmulti'),countfolder=[],countfile=[]"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false && count_showdelete !== 0"
            :dark="color.darkTheme"
            @click="fn_multidelete_v2(),(check_show_button=true), (check_show_progress = true),countfolder=[],countfile=[]"
            >{{$t('dialogmultidelete.buttondelete')}}</v-btn
          >
          <!-- CANCEL  -->
          <v-btn color="red" depressed :dark="color.darkTheme" v-if="check_show_cancel"
          @click="cancelDelete()"> {{ $t('dialogmultidelete.deletecancel') }}</v-btn>
          <!-- ลบเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
          <v-btn color="red" depressed :disabled="check_dis_button" v-if="check_show_button===true"   :dark="check_dis_button ? false : true"
          @click="cleardataall">{{$t('dialogmultidelete.buttonclose')}}</v-btn> 
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { CancelToken } from "axios";
import CryptoJS from "crypto-js";
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "filedata", "status_data"],
  data: function() {
    return {
      count_showdelete:0,
      countfolder:[],
      countfile:[],
      count_folder:0,
      count_file:0,
      check_dis_button:true,
      check_show_progress: false,
      check_show_button: false,
      showdelete: [],
      showfilelock:[],
      showcannotdelete:[],
      count_item: 0,
      countshowdelete:0,
      countshowcannotdelete:0,
      cancelSource: "", // mai
      check_cancel_token: false, // mai
      check_show_cancel: false, // mai

    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.$emit("closeDrag");
        this.fn_loaddata();
        this.check_show_button = false;
        console.log(this.filedata);
      } else {
        this.$emit("openDrag");
        
        this.check_show_progress = false;
        this.showdelete = [];
        this.showcannotdelete = [];
        this.showfilelock = [];
        this.count_item = 0;
      }
    },
  },
  methods: {
    fn_loaddata() {
      //เช็คว่าข้อมูลที่เลือกมีสิทธ์ลบหรือไม่ ถ้ามีก็จะให้ยิง api ลบได้เลย ถ้าไม่มีจะอัปเดต status ที่โชว์ว่าไม่มีสิทธิ์ลบไฟล์นั้น
      for (let i = 0; i < this.filedata.length; i++) {
        if(this.filedata[i].file_status !== 'M'){
         if(this.filedata[i].file_type === 'folder'){
          this.countfolder.push(this.filedata[i])
          console.log('this.countfolder',this.countfolder);
        }else{
          this.countfile.push(this.filedata[i])
          console.log('this.countfolder',this.countfile);
        }
        }
          this.count_folder = this.countfolder.length
          this.count_file = this.countfile.length
          this.count_item++;
          console.log(this.filedata[i]);
          let datadelete = {};
          datadelete["file_name"] = this.filedata[i]["file_name"];
          datadelete["file_id"] = this.filedata[i]["file_id"];
          datadelete["file_icon"] = this.filedata[i]["file_icon"];
          datadelete["file_status"] = this.$t('dialogmultidelete.deleting');
          datadelete["file_value"] = 0;
          datadelete["show_name"] = false;
          datadelete["file_type"] = this.filedata[i]["file_type"];
          datadelete["status_lock_data"] = this.filedata[i]["status_lock_data"];
          if ((this.filedata[i].file_permission_2 === "05" || this.filedata[i].file_permission_2 === "06") && this.filedata[i].status_lock_data === "N") {
            // console.log("(this.filedata[i].file_permission.delete");
            datadelete["permission_delete"] = true;
            this.showdelete.push(datadelete);
            this.count_showdelete = this.showdelete.length
          } else if((this.filedata[i].file_permission_2 === "05" || this.filedata[i].file_permission_2 === "06") && this.filedata[i].status_lock_data === "Y"){
            datadelete["permission_delete"] = false;
            this.showfilelock.push(datadelete);
            console.log("this.showfilelock",this.showfilelock);
          }else {
            datadelete["permission_delete"] = false;
            this.showcannotdelete.push(datadelete);
          }
        }
      
      this.countshowdelete = this.showdelete.length;
      this.countshowcannotdelete = this.showcannotdelete.length;
    },

    async file_id_group (file_id, per_chunck) { 
    const result = file_id.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/per_chunck)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
      }, [])

     return result
    },

     // ## จัด Element node treeview ตาม group ไฟลโฟลเดอ
     async slice_node_treeview_chunck(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
          const chunk = arr.slice(i, i + chunkSize);
          res.push(chunk);
      }
      return res;
    },

    //ฟังก์ชั่นวนลบไฟล์
    async fn_multidelete() {
      this.check_dis_button = true;
      for (let i = 0; i < this.filedata.length; i++) {
        if(this.filedata[i].file_status !== 'M'){
        let objIndex = this.showdelete.findIndex((obj) => obj.file_id === this.filedata[i].file_id);
        let objIndexconnotdelete = this.showcannotdelete.findIndex((obj) => obj.file_id === this.filedata[i].file_id);
        let objIndexfilelock = this.showfilelock.findIndex((obj) => obj.file_id === this.filedata[i].file_id);
        //เช็คเพื่อนวนส่งข้อมูลเฉพาะไฟล์ที่มีสิทธิ์ลบ
        if ((this.filedata[i].file_permission_2 === "05" || this.filedata[i].file_permission_2 === "06") && this.filedata[i].status_lock_data === "N") {
          this.showdelete[objIndex]["file_status"] = this.$t('dialogmultidelete.deleting');
          let payload = {
            account_id: this.dataAccountId,
            user_id: this.dataUsername,
            data_id: this.filedata[i]["file_id"],
            data_type: this.filedata[i].file_type === "folder" ? "folder" : "file",
            status_data: this.status_data,
          };

          await this.fn_delete(payload, this.filedata[i].file_id).then(async (res) => {
              //  if(res.status === "delete success"){
            //  }else{
            //  }
          });
        } else if((this.filedata[i].file_permission_2 === "05" || this.filedata[i].file_permission_2 === "06") && this.filedata[i].status_lock_data === "Y"){
          this.showfilelock[objIndexfilelock]["file_status"] = this.$t('dialogmultidelete.cannotdelete');
          this.showfilelock[objIndexfilelock]["file_value"] = 500;

        } else{
          this.showcannotdelete[objIndexconnotdelete]["file_status"] = this.$t('dialogmultidelete.cannotdelete');
          this.showcannotdelete[objIndexconnotdelete]["file_value"] = 500;
         
        }
        if(this.filedata.length - i === 1){
          this.check_dis_button = false;
        }
          }
      }
      
    },

    async cleardataall () {
      this.$emit('closedialogreload')
      this.$emit('closebuttonmulti')
      this.countfolder = []
      this.countfile = []
      this.check_cancel_token = false
      this.cancelSource = ""
    },

    async fn_multidelete_v2 () {
      this.check_show_cancel = true
      this.check_dis_button = true;
      let chunck_group_array = this.showdelete.length <= 1 ?
      [this.showdelete] : await this.file_id_group(this.showdelete.filter(
        (item)=> item.permission_delete === true), 5) //จัด group ชุดละ 5

        for(var i = 0; i < chunck_group_array.length; i++ ){
        let check_error_round = false // เช็ค error แต่ละรอบ
        this.cancelSource = CancelToken.source()
        const promise = await chunck_group_array[i].map(async (item) => { // สร้าง map นำไปใช้ใน promise.all
          item.file_status = this.$t('dialogmultidelete.deleting'); // เปลี่ยน status
          let payload = {
            account_id: this.dataAccountId,
            user_id: this.dataUsername,
            data_id: item.file_id,
            data_type: item.file_type === "folder" ? "folder" : "file",
            status_data: this.status_data,
          };
          const responseDelete = await this.fn_delete_v2(payload, item.file_id)
          return responseDelete
        })

        await Promise.all(promise) // Promise.all [promise, ...]
        .then( async (AllRes) => {
          for(const itemFile of AllRes){
            let findIndexItem = this.showdelete.findIndex((item) => item.file_id === itemFile.id)
            if(itemFile.status === 'OK'){
              this.showdelete[findIndexItem]['file_status'] = this.$t('dialogmultidelete.deletesuccess');
              this.showdelete[findIndexItem]["file_value"] = 100;
            } else if (itemFile.status === 'FILE/FOLDER ERROR') {
              this.showdelete[findIndexItem]['file_status'] = this.$t('dialogmultidelete.deletefail');
              this.showdelete[findIndexItem]["file_value"] = 500;
            } else if (itemFile.status === 'AXIOS ERROR') {
              this.showdelete[findIndexItem]['file_status'] = this.$t('dialogmultidelete.deletefail');
              this.showdelete[findIndexItem]["file_value"] = 500;
              check_error_round = true
            } else {
              this.showdelete[findIndexItem]['file_status'] = this.$t('dialogmultidelete.deletecancel');
              this.showdelete[findIndexItem]["file_value"] = 500;
              check_error_round = true
            }
          }
        })

        if(check_error_round){ // ถ้าหากรอบไหนมี error Axios/Network
          this.check_dis_button = false;
          this.check_show_cancel = false
          this.showdelete.filter((item) => item.file_value === 0 && item.permission_delete === true ).forEach((item) => {
            item.file_status =  this.$t('dialogmultidelete.deletecancel') 
            item.file_value = 500
          })
          break;
        }
        // slide ตามชุดจำนวน
        let group_element_node_ = await this.slice_node_treeview_chunck(Array.from(document.getElementsByClassName('v-list-item-filefolder')[0].children), 5)
        this.scrollToBottom(group_element_node_[i][group_element_node_[i].length - 1])

        if(chunck_group_array.length - 1 === i){
          // Read clip and decrypt
          try{
            var ReadClipBoard = await navigator.clipboard.readText() // READ CLIPBOARD
          } catch (err) {
            console.log("ERROR CLIPBOARD ", err)
          }
          console.log("ReadddClip ", ReadClipBoard)
          if(ReadClipBoard !== ""){
            try{
              var JSONParse_ClipArray = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(ReadClipBoard, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }))) 
              let prepare_date_multifile = this.showdelete
              let finish_delete = prepare_date_multifile
              .filter((filefiler) => filefiler.file_status === this.$t('dialogmultidelete.deletesuccess') && filefiler.file_value === 100 )
              .map((itemId) => itemId.file_id)
              console.log("Finished delte ", finish_delete)
              finish_delete.forEach((itemF) => {
                let finded_index = JSONParse_ClipArray.array_files.findIndex((fileId) => fileId === itemF)
                if(finded_index >= 0 ){
                  JSONParse_ClipArray.array_files.splice(finded_index, 1)
                }
              })
              if(JSONParse_ClipArray.array_files.length >= 1){
                console.log("JSON OBJECT ", JSONParse_ClipArray)
                // ทำการ encrypt ใหม่
                var create_encrypt = CryptoJS.AES.encrypt(JSON.stringify(JSONParse_ClipArray), 
                CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }).toString()
                navigator.clipboard.writeText(create_encrypt)
              } else {
                navigator.clipboard.writeText("")
              }
            } catch (err) {
              console.log("HELLO Tootj ", JSONParse_ClipArray)
              navigator.clipboard.writeText("")
            }
          }
          this.check_dis_button = false;
          this.check_show_cancel = false
        }

        }
      
    },
    scrollToBottom(item) {
      item.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth' })
    },
    //ลบไฟล์/โฟลเดอร์
    async fn_delete(payload, file_id) {
      let objIndex = this.showdelete.findIndex((obj) => obj.file_id === file_id);
      let auth = await gbfGenerate.generateToken();
      let result = await this.axios({
        method: "POST",
        url: process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_data_to_trash", // เก่า /api/remove_file_folder_to_trash
        data: payload,
        headers: { Authorization: auth.code },

        onUploadProgress: (e) => {
          if ((e.loaded / e.total) * 100 >= 95) {
            this.showdelete[objIndex]["file_value"] = 95;
          } else {
            this.showdelete[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
          }
        },
      });
      return new Promise((resolve, reject) => {
        try {
          if (result.data.status === "OK") {
            this.showdelete[objIndex]["file_status"] = this.$t('dialogmultidelete.deletesuccess');
            this.showdelete[objIndex]["file_value"] = 100;
             setTimeout(() => {
                  resolve({ status: "delete success", msg: "OK", code: "" });
                    }, 500);
            
          } else {
            this.showdelete[objIndex]["file_status"] = this.$t('dialogmultidelete.deletefail');
            this.showdelete[objIndex]["file_value"] = 500;
            setTimeout(() => {
                  resolve({ status: "delete denied", msg: ": ", code: "" });
                    }, 500);
            
          }
        } catch (err) {
          setTimeout(() => {
                   resolve({ status: "delete denied", msg: ": " + err, code: "" });
                    }, 500);
          this.showdelete[objIndex]["file_status"] = this.$t('dialogmultidelete.deletefail');
          this.showdelete[objIndex]["file_value"] = 500;
        }
      });
    },

    async fn_delete_v2 (payload, file_id) { // delete v2
      let auth = await gbfGenerate.generateToken();
      if(this.check_cancel_token) {
        this.cancelSource.cancel("Cancel All")
      }
      return await this.axios
      .post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/remove_data_to_trash",
        payload,
        {
          headers: { Authorization: auth.code }, 
          cancelToken: this.cancelSource.token,
          onUploadProgress: (e) => {
            let findIndexItem = this.showdelete.findIndex((item) => item.file_id === file_id)
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showdelete[findIndexItem]["file_value"] = 95;
            } else {
              this.showdelete[findIndexItem]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          } 
        },
        
      )
      .then((response) => {
        let findIndexItem = this.showdelete.findIndex((item) => item.file_id === file_id)
        if(response.data.status === 'OK'){
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
                status: 'OK',
                message: 'Success',
                id: file_id
              })
            },500)
          })
        } else {
          this.showdelete[findIndexItem].file_status = this.$t('dialogmultidelete.deletefail');
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
                status: 'FILE/FOLDER ERROR',
                message: 'Error',
                id: file_id
              })
            },500)
          })
        }
      })
      .catch((err)=> {
        let findIndexItem = this.showdelete.findIndex((item) => item.file_id === file_id)
        if(this.axios.isCancel(err)){
        console.log("Error Cancel ", err)
          return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({
                  status: 'CANCEL',  //AXIOS ERROR
                  message: 'Error',
                  id: file_id
                })
              },500)
        }) 
        } else {
          this.showdelete[findIndexItem].file_status = this.$t('dialogmultidelete.deletefail');
          return new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve({
                    status: 'AXIOS ERROR',  //AXIOS ERROR
                    message: 'Error',
                    id: file_id
                  })
                },500)
          }) 
        }
      })
    },

    async cancelDelete () {
      this.check_cancel_token = true
    },

  },
};
</script>
<style></style>
